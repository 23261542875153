/* 公共引入,勿随意修改,修改时需经过确认 */
import Vue from "vue";
import "normalize.css";
import "@/assets/css/index.scss";
import "./vant"; // 导入 vant
import "./element"; // 导入 element-ui
import "./dayjs";
import "./component";

// 移动端适配
import "lib-flexible";

import * as uaParser from "./uaParser";
// console.log("uaParser=>isMobile", uaParser.isMobile());
// console.log("uaParser=>isSafari", uaParser.isSafari());
Vue.prototype.$uaParser = uaParser;

import api from "@/api/index";
Vue.prototype.$api = api;
