import Vue from "vue";
import VueRouter from "vue-router";
import { constantRoutes } from "./constantRoutes";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  // console.log(location, onResolve, onReject);
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => {
    return err;
  });
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  // console.log(location, onResolve, onReject);
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject);
  }
  return originalReplace.call(this, location).catch((err) => {
    return err;
  });
};
Vue.use(VueRouter);

const createRouter = () => {
  return new VueRouter({
    // mode: "history", // 默认 hash
    base: process.env.BASE_URL,
    // scrollBehavior: () => {
    //   return { y: 0 };
    // },
    routes: constantRoutes,
  });
};

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
