import request from "@/utils/request";

// 本地测试
export const test = (params = {}, data = {}) => {
  return request({
    url: "/getlist?query=1",
    method: "get",
    params,
    data: {
      username: "admin",
      password: "123456",
    },
    // isLoading: false,
  });
};

// 表单提交
export const formReview = (data = {}, params = {}) => {
  return request({
    url: "/website/v1/review",
    method: "post",
    params,
    data,
    // isLoading: false,
  });
};
