import axios from "axios";
// import { Loading } from "element-ui";
import { Toast } from "vant";
import { baseURL, prefix, title, proxy } from "@/setting/index";
console.warn("vue.config.js=>setting", { baseURL, prefix, title, proxy });
import vueconfig from "../../vue.config.js";
console.warn("vue.config.js", vueconfig);

let loadingInstance = "";
// 允许同时存在多个 Toast
Toast.allowMultiple();

const instance = axios.create({
  baseURL: proxy ? prefix : baseURL, // 不开启代理 走 axios.baseURL + 业务Api
  timeout: 5000, // 指定请求超时的毫秒数
});

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    const { headers, isLoading = true } = config;
    console.log("config", config);

    if (isLoading) {
      loadingInstance = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "拼命加载...",
      });
    }
    // headers["token"] = "test-token";
    headers["application-code"] = "iluvatar_cn";
    headers["application-type"] = "website";

    return config;
  },
  (error) => {
    // 对请求错误做些什么
    console.error(`请求拦截器=>error`, error);
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    loadingInstance && loadingInstance.close();
    const { data = "", status = "", statusText = "" } = response;
    console.log("响应拦截器=>2xx 范围内", response);
    if (statusText === "OK" || status === 200) {
      return Promise.resolve(data);
    }
    return Promise.reject(response);
  },
  (error) => {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    loadingInstance && loadingInstance.close();
    const { status = "" } = error;
    console.error(`响应拦截器=>超出 2xx 范围`, error);
    if (status === 401) {
      // 处理网络层 401 状态
      console.log("网络层错误", status);
      return Promise.reject(401);
    }
    return Promise.reject(error);
  }
);

export default instance;
