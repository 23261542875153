const UaParser = require("ua-parser-js");
const parser = new UaParser();

// console.log("ua-parser-js", {
//   UaParser,
//   parser,
// });

// 设备类型
export const isMobile = () => {
  return parser.getDevice().type === "mobile";
};

// 判断是否 Safari
export const isSafari = () => {
  return parser.getBrowser().name === "Safari";
};
