// 按需引入 vant组件
import Vue from "vue";
import {
  Button,
  List,
  Cell,
  Tabbar,
  TabbarItem,
  RadioGroup,
  Radio,
  Image,
  Loading,
  Lazyload,
  Toast,
  Popover,
  Popup,
  Form,
  Field,
  Calendar,
} from "vant";

Vue.use(Button);
Vue.use(Cell);
// Vue.use(List);
// Vue.use(Tabbar).use(TabbarItem);
Vue.use(RadioGroup).use(Radio);
// Vue.use(Image).use(Loading).use(Lazyload);
// Vue.use(Toast);
// Vue.use(Popover);
// Vue.use(Popup);
Vue.use(Form).use(Field);
Vue.use(Calendar);

// https://vant-contrib.gitee.io/vant/v2/#/zh-CN/advanced-usage#zhuo-mian-duan-gua-pei
import "@vant/touch-emulator";
