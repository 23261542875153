export const constantRoutes = [
  {
    path: "/",
    redirect: "/form",
  },
  {
    path: "/home",
    name: "Home",
    component: () => {
      return import("@/views/home/index.vue");
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => {
      return import("@/views/about/index.vue");
    },
  },
  {
    path: "/form",
    name: "Form",
    component: () => {
      return import("@/views/form/index.vue");
    },
    meta: { title: "表单" },
  },
  {
    path: "/401",
    name: "Abnormal",
    component: () => {
      return import(/* webpackChunkName: "error" */ "@/components/ErrorPage/401.vue");
    },
  },
  {
    path: "/404",
    name: "NotFound",
    component: () => {
      return import(/* webpackChunkName: "error" */ "@/components/ErrorPage/404.vue");
    },
  },
  {
    path: "*",
    redirect: "/404",
  },
];
