const path = require("path");
const { baseURL, prefix, title } = require("./src/setting/index");
// console.warn("nuxt.config.js=>setting", { baseURL, prefix, title });

const resolve = (dir) => {
  return path.resolve(__dirname, dir);
};

module.exports = {
  // lintOnSave: false,
  // publicPath: "./", // 默认：'/' 部署应用包时的基本 URL,
  // outputDir: "dist/h5", // 默认 "dist"
  devServer: {
    port: 9527,
    open: false,
    https: false,

    // 配置跨域
    proxy: {
      [prefix]: {
        target: baseURL,
        secure: false,
        ws: true,
        changeOrigin: true,
        pathRewrite: {
          // 正则替换掉 /dev 剩余追加
          [`^${prefix}`]: "/", // 匹配 baseUrl = '/dev',
          "^/*": "/", // 匹配 baseUrl = '/dev/*'
        },
      },
      // 匹配 /dev
      // "/dev": {
      //   target: "http://127.0.0.1:7777/v1",
      //   secure: false,
      //   ws: true,
      //   changeOrigin: true,
      //   pathRewrite: {
      //     // 正则替换掉 /dev 剩余追加
      //     "^/dev": "/", // 匹配 baseUrl = '/dev',
      //     "^/*": "/", // 匹配 baseUrl = '/dev/*'
      //   },
      // },
    },

    overlay: {
      //  当出现编译器错误或警告时，在浏览器中显示全屏覆盖层
      warnings: true,
      errors: true,
    },
  },
  css: {
    loaderOptions: {
      scss: {
        // 引入全局样式变量
        prependData: `
                    @import "@/assets/css/scoped-element-variables.scss";
                    @import "@/assets/css/scoped-layout.scss";
                    @import "@/assets/css/scoped-mixin.scss";
                `,
      },
      less: {
        modifyVars: {
          hack: `true; @import "/src/assets/css/scoped-vant-variables.less";`, // 绝对路径
        },
      },
    },
  },
  // 合并到最终的配置中
  configureWebpack: (config) => {},
  // webpack 配置进行更细粒度的修改
  chainWebpack: (config) => {
    // 设置 title
    config.plugin("html").tap((args) => {
      args[0].title = title;
      return args;
    });
    // 设置 alias
    config.resolve.alias
      .set("@", resolve("src"))
      .set("assets", resolve("src/assets"))
      .set("components", resolve("src/components"))
      .set("views", resolve("src/views"));
  },
  // 离线缓存
  pwa: {
    name: "XYZ", // name short_name
    themeColor: "#ffffff", // theme_color
    msTileColor: "#000000", // background_color
    appleMobileWebAppCapable: "yes",
    appleMobileWebAppStatusBarStyle: "black",
    workboxPluginMode: "GenerateSW", // InjectManifest GenerateSW

    assetsVersion: "",
    // manifestPath: "",
    manifestOptions: "",
    manifestCrossorigin: "",
    iconPaths: {
      faviconSVG: "favicon.ico",
      favicon32: "favicon.ico",
      favicon16: "favicon.ico",
      appleTouchIcon: "favicon.ico",
      maskIcon: "favicon.ico",
      msTileImage: "favicon.ico",
      // faviconSVG: "img/icons/favicon.png",
      // favicon32: "img/icons/favicon-32x32.png",
      // favicon16: "img/icons/favicon-16x16.png",
      // appleTouchIcon: "img/icons/apple-touch-icon-152x152.png",
      // // maskIcon: "img/icons/safari-pinned-tab.svg",
      // msTileImage: "img/icons/msapplication-icon-144x144.png",
    },
  },
};
